import { FC, useEffect, useState } from 'react'
import styles from './Shop.module.scss'
import { ShopProps2 } from 'components/Phantom/_pageCompositions/Shop/types'
import { ProductTitleConnected } from 'components/Phantom/_shop/ProductTitle'
import { Modal } from 'components/Phantom/_shop/Modals/Modal'
import { Sticky } from 'components/Phantom/_shop/Sticky'
import { ModalKey, ModalMappingConnected } from 'components/Phantom/_shop/Modals/modalMapping'
import { usePrevious } from 'components/_hooks/usePrevious'
import { SelectionSummary } from 'components/Phantom/_shop/SelectionSummary'
import { SideNav } from 'components/Phantom/_shop/SideNav'
import { observer } from 'mobx-react'
import { useRootStore } from 'components/_hooks/useRootStore'
import { Selection } from 'components/Phantom/_shop/Selection'
import { toJS } from 'mobx'
import { useScrollThreshold } from 'components/_hooks/useScrollThreshold'
import { scrollLocked } from 'utils/jss'
import { findCurrentSection } from 'components/Phantom/_shop/utils'
import { UpsellContainer } from 'components/WebEv/Shop/SelectionContainers'
import { ShopImages } from 'components/Phantom/_shop/ShopImages'
import cx from 'classnames'
import useABTestVariant from 'hooks/useABTestVariant'
import { internationalDefaultSelectionAB } from 'ab_testing'
import { getRegionPod4DefaultKey } from 'prices'

export const ConnectedShop: FC = observer(() => {
	const { shopStore, settingsStore } = useRootStore()

	const region = settingsStore.currentRegion
	const i18nShopSelectionAB = useABTestVariant(internationalDefaultSelectionAB, 1)

	useEffect(() => {
		if (i18nShopSelectionAB) {
			shopStore.setSelection('pod-model', 'pod_4')
			shopStore.handleSelection('size', getRegionPod4DefaultKey(region))
		}
	}, [])

	const selectedSelections = shopStore.selectedSelections
	//
	const productData = {
		images: shopStore.images,
		// data: shopStore.podData.upsells,
		data: shopStore.selectionData,
		productInfo: shopStore.productInfo,
		summaryInfo: shopStore.summaryInfo,
		stickyData: shopStore.stickyData,
		selectedVariant: shopStore.selectedVariant,
		selectionHandler: shopStore.handleSelection,
		handleCheckout: shopStore.checkout,
	}

	if (!productData) {
		return undefined
	}

	return (
		<Shop
			{...productData}
			selectedSelections={toJS(selectedSelections)} // doesnt rerender without toJS
			modal={shopStore.activeModal}
			selectModal={shopStore.selectModal}
			closeModal={shopStore.closeModal}
			selectionImageIndex={shopStore.selectionImageIndex}
			totalImages={shopStore.images.length}
			images={shopStore.images}
			error={shopStore.error}
			goingToCheckout={shopStore.goingToCheckout}
		/>
	)
})

/**
 * For testing purposes. **Real shop page should use Connected components**
 */
export const Shop: FC<ShopProps2> = (props) => {
	// TODO should this state be kept in the shopStore? Probably, why not?
	const currentModal = props.modal
	const prevModal: ModalKey = usePrevious(currentModal)
	const [stickyPreventionInView, setStickyPreventionInView] = useState(false)

	const headerWiped = useScrollThreshold()

	useEffect(() => {
		const header = document.querySelector('header')

		const resizeListener = () => {
			if (header && window.innerWidth >= 1024 && headerWiped) {
				header.style.transform = 'translateY(-100%)'
				header.style.transition = 'transform 0.3s ease-in-out'
			} else if (!scrollLocked) {
				header.style.transform = 'none'
				header.style.transition = 'transform 0.3s ease-in-out'
			}
		}

		resizeListener()

		const selectionSummaryElement = document.getElementById('selection-summary-button')

		const observer = new IntersectionObserver((entries) => {
			setStickyPreventionInView(entries[0].isIntersecting)
		})

		if (selectionSummaryElement) {
			observer.observe(selectionSummaryElement)
		}

		window.addEventListener('resize', resizeListener)
		return () => {
			observer.disconnect()
			window.removeEventListener('resize', resizeListener)
		}
	}, [headerWiped])

	return (
		<main>
			<Sticky
				{...props.stickyData}
				open={headerWiped && !stickyPreventionInView}
				loading={props.goingToCheckout}
				handleCheckout={props.handleCheckout}
			/>
			<Modal
				open={!!currentModal}
				handleClose={props.closeModal}
			>
				{currentModal ? ModalMappingConnected[currentModal] : prevModal ? ModalMappingConnected[prevModal] : null}
			</Modal>
			<ShopMainContainer {...props} />
		</main>
	)
}

const ShopMainContainer = (props: ShopProps2) => {
	const { data, summaryInfo, selectionHandler, modal, selectModal, closeModal, images, error } = props
	const [imageIndex, setImageIndex] = useState(0)

	useEffect(() => {
		let lastSection = 0
		const scrollHandler = () => {
			if (window.innerWidth < 1024) {
				return
			}
			const currentSection = findCurrentSection()
			const section = document.querySelector(`#shop-section-${currentSection}`)
			const selectionElement = document.querySelector(`#shop-section-${currentSection} .shop-section`)
			const numSections = document.querySelectorAll('[id^="shop-section-"]').length

			let imIndex = -1

			if (section && selectionElement && 'firstChild' in section && 'id' in selectionElement) {
				const sectionSelectionId = selectionElement.id.split('selection-')[1]
				if (sectionSelectionId.length > 1) {
					const imageIndex = window.rootStore.shopStore.selectionImageIndex[sectionSelectionId]
					imIndex = imageIndex
				}
			}

			if (currentSection === 0) {
				imIndex = 0
			}

			if (currentSection === numSections - 1) {
				imIndex = window.rootStore.shopStore.images.length - 1
			}

			if (currentSection === lastSection || imIndex < 0) return
			lastSection = currentSection
			setImageIndex(imIndex)
		}

		window.addEventListener('scroll', scrollHandler, { passive: true })
		scrollHandler()
		return () => {
			window.removeEventListener('scroll', scrollHandler)
		}
	}, [])

	return (
		<>
			<div
				className={styles.container}
				id="shop"
			>
				<div className={styles.container_inner}>
					<div className={styles.carousel_wrapper}>
						<ProductTitleConnected />
						<ShopImages
							images={images}
							selectedIndex={imageIndex ?? 0}
						/>
					</div>
					<div className={styles.sections}>
						<div
							className={styles.first_section}
							id={'shop-section-0'}
						>
							<ProductTitleConnected />
							<Selection
								{...data[0]}
								selectionType={'card-selection'}
								selectionProps={{
									options: data[0].options,
									onChange: selectionHandler,
								}}
								modalClick={selectModal}
								currentSelection={props.selectedSelections[data[0].id]}
							/>
						</div>
						{data.slice(1).map((section, i) => {
							return (
								<div
									id={`shop-section-${i + 1}`}
									key={`shop-section-${i + 1}`}
								>
									{section.id !== 'essentials-bundle' && section.id !== 'eight-mattress' && (
										<>
											<div className={cx(styles.carousel_wrapper, styles.mobile_only)}>
												<ShopImages
													images={images}
													// selectedIndex={imageIndex ?? 0}
													imageOverride={i + 1}
												/>
											</div>
											<Selection
												{...section}
												selectionType={'card-selection'}
												selectionProps={{
													options: section.options,
													onChange: selectionHandler,
												}}
												error={error?.associatedSelectionId === section.id ? error.errorText : undefined}
												modalClick={selectModal}
												currentSelection={props.selectedSelections[section.id]}
											/>
										</>
									)}

									{(section.id === 'essentials-bundle' || section.id === 'eight-mattress') && (
										<>
											<div className={cx(styles.carousel_wrapper, styles.mobile_only)}>
												<ShopImages
													images={images}
													// selectedIndex={imageIndex ?? 0}
													imageOverride={i + 1}
												/>
											</div>
											<UpsellContainer
												selectionData={{
													title: section.title,
													description: section.subtitle,
													id: section.id,
													type: 'upsell',
													onChange: selectionHandler,
													checked: props.selectedSelections[section.id] && props.selectedSelections[section.id] !== 'none' ? true : false,
													data: {
														hiddenProductName: section.options[1].data.title,
														currentSelection: props.selectedSelections[section.id],
														price: section.displayPricing.price,
														strikePrice: section.displayPricing.comparePrice,
														discount: section.displayPricing.discountText,
														productTitle: section.options[1].data.title,
														productDescription: section.options[1].data.subtitle,
														icons: section.options[1].data.icons,
													},
												}}
												error={error?.associatedSelectionId === section.id ? error.errorText : undefined}
												modalButtons={section.modalButtons}
												modalClick={selectModal}
											/>
										</>
									)}
								</div>
							)
						})}
						<div className={cx(styles.carousel_wrapper, styles.mobile_only)}>
							<ShopImages
								images={images}
								// selectedIndex={imageIndex ?? 0}
								imageOverride={images.length - 1}
							/>
						</div>
						<div id={`shop-section-${data.length}`}>
							<SelectionSummary
								{...summaryInfo}
								loading={props.goingToCheckout}
								handleCheckout={props.handleCheckout}
							/>
						</div>
					</div>
				</div>
				<SideNavContainer {...props} />
			</div>
		</>
	)
}

const SideNavContainer = (props: ShopProps2) => {
	const { data } = props
	const [currentSection, setCurrentSection] = useState(0)

	useEffect(() => {
		const scrollHandler = () => {
			setCurrentSection(findCurrentSection())
		}

		window.addEventListener('scroll', scrollHandler, { passive: true })
		scrollHandler()
		return () => {
			window.removeEventListener('scroll', scrollHandler)
		}
	}, [])

	return (
		<div className={styles.side_nav_outer}>
			<div className={styles.side_nav_container}>
				<SideNav
					numSlices={data.length + 1}
					onClick={(i) => {
						const sectionId = `shop-section-${i}`
						const section = document.getElementById(sectionId)
						if (section) {
							section.scrollIntoView({ behavior: 'smooth', block: 'center' })
						}
					}}
					selectedSlice={currentSection}
				/>
			</div>
		</div>
	)
}

import * as React from 'react'
import { FC } from 'react'
import shopStyles from 'components/WebEv/Shop/Shop.module.scss'
import { ProductModelSelect } from 'components/ProductModelSelect'
import { Button } from 'components/WebEv/Buttons'
import { information_circle } from 'components/Icon'
import { Colors } from 'components/WebEv/Colors/colors'
import { useShop } from 'components/WebEv/Shop/context/ShopProvider'
import { CardSelectionData } from 'components/WebEv/Shop/types'
import cx from 'classnames'

interface CardSelectionProps {
	selectionData: CardSelectionData
}

export const CardSelection: FC<CardSelectionProps> = (props) => {
	const { state, dispatch } = useShop()
	const { selectionData } = props
	const { title, description, data, modal, field, error } = selectionData

	const currentSelection = state.activeSelections[selectionData.id]?.value
	const selectedModel = selectionData.data.labels.findIndex((label) => label.id === currentSelection)

	return (
		<div className={cx(shopStyles.selection_container, { [shopStyles.selection_container_error]: !!error })}>
			<h2>{title}</h2>
			<p>{description}</p>
			<ProductModelSelect
				labels={data.labels}
				legendLabel={`Selection for ${title}`}
				selectedModel={selectedModel}
				onCheck={(index) => {
					dispatch({
						type: 'updateSelection',
						data: {
							groupID: selectionData.id,
							selectionValue: data.labels[index].id,
							field: field,
						},
					})
				}}
				new={true}
			/>
			{modal && (
				<Button.Empty
					className={shopStyles.modal_button}
					id={`modal-trigger-${title.toLowerCase().split(' ').join('-')}`}
					iconRight={information_circle}
					iconColor={Colors.Neutral.neutral2.hex}
					onClick={() => {
						dispatch({ type: 'updateModal', data: { modal: modal.modal } })
					}}
				>
					{modal.triggerText}
				</Button.Empty>
			)}
		</div>
	)
}

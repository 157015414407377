import { ShopState, UpsellSelectionData } from 'components/WebEv/Shop/types'
import { Currency } from 'prices/types'
import { PriceManager } from 'prices'

export const getBadgeText = (state: ShopState, currency: Currency) => {
	const mainDiscount = state.discount

	// TODO add support for percentage discounts
	if (mainDiscount.type !== 'amount') return null

	let totalDiscount = mainDiscount.value

	const mattressUpsell = state.data.selections.find((selection) => selection.id === 'mattress')
	if (mattressUpsell && state.activeSelections.mattress.value === 'true') {
		totalDiscount -= (mattressUpsell as UpsellSelectionData).data.discount.value
	}

	const bundleUpsell = state.data.selections.find((selection) => selection.id === 'sleepessentialsbundle')
	let upsellDiscounts = 0

	const sleepEssentialsValue = state.activeSelections['sleepessentialsbundle']?.value
	const sleepEssentialsSelected = sleepEssentialsValue === 'true'

	if (bundleUpsell) {
		const bundleDiscount = (bundleUpsell as UpsellSelectionData).data.discount.value
		upsellDiscounts += bundleDiscount
		totalDiscount -= sleepEssentialsSelected ? bundleDiscount : 0
	}

	if (sleepEssentialsSelected || upsellDiscounts === 0) return null

	const discount = PriceManager.formatPriceToCurrencyNoDecimal(totalDiscount + upsellDiscounts, currency)
	return {
		text: `Bundle and save ${discount}`,
		discount: discount,
	}
}

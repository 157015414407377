import { FC, PropsWithChildren, useEffect, useRef } from 'react'
import styles from 'components/Phantom/_shop/Modals/Modal/Modal.module.scss'
import { ModalProps } from 'components/Phantom/_shop/Modals/Modal/types'
import { useClickOutsideModal } from 'hooks/useClickOutsideModal'
import { Icon } from 'components/Phantom/Icon'
import { Button } from 'components/Phantom/Button'
import { setPageScroll } from 'utils'

/**
 * Modal wrapper component. Purely presentational, does not need to be connected to a store
 */
export const Modal: FC<ModalProps & PropsWithChildren> = (props) => {
	const { open, handleClose } = props

	const containerRef = useRef(null)

	useClickOutsideModal(containerRef, handleClose)

	useEffect(() => {
		setPageScroll(!open)
	}, [open])

	return (
		<div
			className={styles.modal_wrapper}
			style={{
				visibility: open ? 'visible' : 'hidden',
				backdropFilter: open ? 'blur(20px)' : 'blur(0)',
				backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
				opacity: open ? 1 : 0,
			}}
		>
			<div
				className={styles.container}
				ref={containerRef}
				style={{
					transform: open ? 'scale(1)' : 'scale(0.95)',
				}}
			>
				<div className={styles.inset_fixed_container}>
					<Button.Empty
						id={'close-modal'}
						onClick={handleClose}
						className={styles.close_modal_button}
					>
						<Icon
							name={'CloseDark'}
							color={'black'}
							size={24}
						/>
					</Button.Empty>
				</div>

				{props.children}
			</div>
		</div>
	)
}

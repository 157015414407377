import { FC } from 'react'
import styles from './CardSelection.module.scss'
import { CardSelectionProps } from './types'
import { CardSelectionItem } from 'components/Phantom/_shop/CardSelectionItem'
import { CardSelectionItemProps } from 'components/Phantom/_shop/CardSelectionItem/types'
import cx from 'classnames'

export const CardSelection: FC<CardSelectionProps> = (props) => {
	const handleChange = (option: CardSelectionItemProps, value: number) => {
		onChange(props.parentId, option.id)
	}

	const { onChange, options, error } = props

	if (!options) return null

	return (
		<div
			className={cx(styles.container, { pod_container: props.parentId === 'pod-model', autopilot_container: props.parentId === 'auto-pilot' })}
			style={{ gridTemplateColumns: getGridLayout(options) }}
		>
			{options.map((option: CardSelectionItemProps, i: number) => (
				<label
					key={`${option.key}${i}`}
					aria-label={option.key}
				>
					<input
						type="radio"
						name="card-selection"
						value={option.key}
						className="vh"
						onChange={() => handleChange(option, i)}
						checked={props.currentSelection === option.id}
					/>
					<CardSelectionItem
						{...option}
						error={error}
						selected={props.currentSelection === option.id}
					/>
				</label>
			))}
		</div>
	)
}

const getGridLayout = (options: CardSelectionItemProps[]) => {
	if (options.length === 0) return ''

	// If it's even, we want 2 columns because we don't want a blank space
	if (options.length % 2 === 0) return '1fr 1fr'

	// Since we can fit 3 items in a row, we want to use 3 columns
	if (options.length % 3 === 0) return 'repeat(auto-fit, minmax(0, 1fr))'

	// But a number like 5 or 7 that doesn't fit into 2 or 3 should just be 1 column
	return '1fr'
}

import { getVisibleAmountPercentage } from 'components/_utils/boundingBoxHelpers'

export const findCurrentSection = () => {
	const sections = document.querySelectorAll('[id^="shop-section-"]')

	let mostInViewIndex = 0
	let mostInView = 0

	for (let i = 0; i < sections.length; i++) {
		const section = sections[i]

		const percentInView = getVisibleAmountPercentage(section)

		if (percentInView > mostInView) {
			mostInView = percentInView
			mostInViewIndex = i
		}
	}

	return mostInViewIndex
}

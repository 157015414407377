import { FC, ReactNode } from 'react'
import styles from './Tooltip.module.scss'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

import 'tippy.js/themes/light.css'
import 'tippy.js/themes/light-border.css'
import 'tippy.js/themes/material.css'
import 'tippy.js/themes/translucent.css'

import 'tippy.js/animations/scale.css'
import 'tippy.js/animations/shift-away.css'

import { TippyPlacement } from 'components/_utils/utilityTypes'
import { amHidTooltip, amShowedTooltip } from 'events/amplitude'

export interface TooltipProps extends TippyProps {
	content: ReactNode
	id: string
	puppet?: 'open' | 'close'
	children?: ReactNode
}

// Feel free to add more to this
// https://atomiks.github.io/tippyjs/v5/all-props/
export interface TippyProps {
	animation?: 'fade' | 'shift-away' | 'scale'
	appendTo?: 'parent' | Element | ((ref: Element) => Element)
	arrow?: boolean
	delay?: number
	duration?: number | [number, number]
	interactive?: boolean
	offset?: [number, number]
	onShow?: () => void
	onHide?: () => void
	placement?: TippyPlacement
	theme?: 'light' | 'light-border' | 'material' | 'translucent' | 'dark' | ''
	trigger?: string
	zIndex?: number
}

export const Tooltip2: FC<TooltipProps> = (props) => {
	const onShowHandler = () => {
		amShowedTooltip(props.id)
		props.onShow?.()
	}

	const onHideHandler = () => {
		amHidTooltip(props.id)
		props.onHide?.()
	}

	return (
		<Tippy
			content={props.content}
			animation={props.animation}
			appendTo={props.appendTo}
			arrow={props.arrow}
			delay={props.delay}
			duration={props.duration}
			interactive={props.interactive}
			offset={props.offset}
			onShow={onShowHandler}
			onHide={onHideHandler}
			placement={props.placement}
			theme={props.theme}
			trigger={props.trigger}
			zIndex={props.zIndex}
			visible={props.puppet ? props.puppet === 'open' : undefined}
		>
			<button
				className={styles.button_wrapper}
				onClick={(e) => e.preventDefault()}
			>
				<>{props.children}</>
			</button>
		</Tippy>
	)
}

Tooltip2.defaultProps = {
	appendTo: 'parent',
	animation: 'fade',
	arrow: true,
	delay: 0,
	duration: [300, 250],
	interactive: false,
	offset: [0, 10],
	placement: 'top',
	theme: 'light',
	trigger: 'mouseenter focus',
	zIndex: 9999,
}

import shopStyles from 'components/WebEv/Shop/Shop.module.scss'
import styles from 'components/WebEv/Shop/HelperComponents/FloatingCheckout/FloatingCheckout.module.scss'
import { PriceManager } from 'prices'
import * as React from 'react'
import { FC, useEffect, useRef } from 'react'
import { Icon } from 'components/Icon'
import { Colors } from 'components/WebEv/Colors/colors'
import { AffirmLogo } from 'components/_const_assets/affirmLogo'
import { Button } from 'components/WebEv/Buttons'
import cx from 'classnames'
import { useShop } from 'components/WebEv/Shop/context/ShopProvider'
import { getBenefits } from 'components/WebEv/Shop/utils/productBenefits'
import { formatDiscount } from 'components/WebEv/Shop/utils/formatDiscount'
import { allProductsList } from 'products/utils'
import { KlarnaLogo } from 'components/_const_assets/klarnaLogo'
import { TruemedInfo } from 'components/TruemedInfo/TruemedInfo'

export const FloatingCheckout: FC = () => {
	const { state, checkout, rootStore } = useShop()
	const { productName, price, strikePrice, discount } = state
	const { currency } = rootStore.priceStore
	const ref = useRef<HTMLDivElement>(null)

	const size = state.activeSelections.size?.value
	const model = state.activeSelections.model?.value
	const product = Object.values(allProductsList).find((p) => p.handle === model)
	const variant = product?.variants[size]
	const variantName = variant ? variant.name : ''

	const name = (
		<h2 className={styles.product_name}>
			{productName}
			{variantName ? ` - ${variantName}` : ''}
		</h2>
	)

	const membershipSelection = Object.values(state.activeSelections).find((s) => s.field === 'membership')
	const membership = membershipSelection ? membershipSelection.value : ''
	const benefits = getBenefits(membership === 'plus' ? 5 : 2)

	useEffect(() => {
		checkModalButton(ref.current?.clientHeight || 0)
	}, [state.floatingCheckoutOpen])

	useEffect(() => {
		const handleResize = () => {
			checkModalButton(ref.current?.clientHeight || 0)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const iconList = (
		<ul className={styles.icon_list}>
			{benefits.map((benefit, index) => (
				<li key={index}>
					<Icon
						icon={benefit.icon}
						color={Colors.Neutral.black.hex}
						className={styles.icon}
					/>
					<p>{benefit.description}</p>
				</li>
			))}
		</ul>
	)

	const pricing = (
		<p className={cx(shopStyles.small_pricing, styles.pricing)}>
			<strong aria-label="Current price">{PriceManager.formatPriceToCurrencyNoDecimal(price, currency)}</strong>
			<s aria-label="Old price">{PriceManager.formatPriceToCurrencyNoDecimal(strikePrice, currency)}</s>
			<span>{formatDiscount(discount, currency)}</span>
		</p>
	)

	const monthlyPrice = PriceManager.getAffirmFinancingAmount(price)
	const financing = (
		<p className={cx(styles.financing)}>
			{currency === 'CAD' ? 'Finance monthly with ' : `Financing as low as ${PriceManager.formatPriceToCurrencyNoDecimal(monthlyPrice, currency)}/mo with `}
			<AffirmLogo />
			<TruemedInfo className={styles.financing} />
		</p>
	)

	const klarna = (
		<p className={cx(styles.financing)}>
			or pay in 3 with <KlarnaLogo color={'black'} />
			<TruemedInfo className={styles.financing} />
		</p>
	)

	const checkoutButton = (
		<Button.Primary
			id={'floating-checkout-button'}
			className={styles.checkout_button}
			onClick={() => checkout('floating-checkout-button')}
			loading={rootStore.cartStoreNew.updatingCart}
		>
			Checkout
		</Button.Primary>
	)

	const affirmCurrency = PriceManager.currencyHasAffirm(currency)
	const klarnaCurrency = PriceManager.currencyHasKlarna(currency)

	const showAffirmOrKlarna = affirmCurrency || klarnaCurrency
	const financingContent = affirmCurrency ? financing : klarnaCurrency ? klarna : null

	const fallbackText = !showAffirmOrKlarna && 'Limited time offer. Ends soon.'

	return (
		<div
			className={cx(styles.floating_checkout_container, { [styles.floating_checkout_closed]: !state.floatingCheckoutOpen })}
			ref={ref}
			style={{
				visibility: state.floatingCheckoutOpen ? 'visible' : 'hidden',
			}}
			id={'floating-checkout'}
		>
			<div className={styles.desktop_container}>
				{name}
				{iconList}
				<div className={styles.desktop_pricing}>
					{pricing}
					{showAffirmOrKlarna && financingContent}
					{fallbackText && <p className={styles.fallback_text}>{fallbackText}</p>}
				</div>

				{checkoutButton}
			</div>
			<div className={styles.mobile_container}>
				<div className={styles.mobile_upper_shelf}>
					<div className={styles.name_pricing}>
						{name}
						{pricing}
					</div>
					<div className={styles.checkout_buttons}>{checkoutButton}</div>
				</div>

				<div className={styles.mobile_lower_shelf}>
					<div className={styles.mobile_lower_shelf_inner}>{financingContent || <p className={styles.fallback_text}>{fallbackText}</p>}</div>
				</div>
			</div>
		</div>
	)
}

const checkModalButton = (refHeight: number) => {
	const modalButton = document.getElementById('floating-modal')
	const floatingCheckout = document.getElementById('floating-checkout')
	if (!modalButton || !floatingCheckout) return

	const floatingCheckoutOpen = floatingCheckout.style.visibility === 'visible'

	if (floatingCheckoutOpen && window.innerWidth <= 1024) {
		modalButton.style.marginBottom = refHeight + 'px'
	} else {
		modalButton.style.marginBottom = '0px'
	}
}

import { ShopState } from 'components/WebEv/Shop/types'
import { RegionShort } from 'utils/internationalization'
import { allProductsList } from 'products/utils'
import { VariantCore, VariantKey } from 'products/types'
import { getRegionCoverName } from 'prices'
import { getShippingText } from 'config/shipping_timelines'

export const getShippingTimeline = (state: ShopState, currentRegion: RegionShort): string => {
	const productSelection = Object.values(state.activeSelections).find((s) => s.field === 'product')
	if (!productSelection) return ''
	const productHandle = productSelection.value

	const product = Object.values(allProductsList).find((p) => p.handle === productHandle)
	if (!product) return ''

	const variantSelection = Object.values(state.activeSelections).find((s) => s.field === 'variant')
	let variant: VariantKey = getRegionCoverName(currentRegion)
	if (variantSelection) {
		variant = variantSelection.value as VariantKey
	}

	const variantObject = product.variants[variant] as VariantCore
	if (!variantObject) return ''

	return getShippingText(variantObject, currentRegion)
}

import { FC } from 'react'
import styles from './PricingInfo.module.scss'
import { PricingInfoProps } from './types'
import cx from 'classnames'

export const PricingInfo: FC<PricingInfoProps & { big: boolean }> = (props) => {
	const { price, comparePrice, discountText } = props
	return (
		<p className={cx(styles.pricing_info, { [styles.small]: !props.big })}>
			<span>{price}</span>
			{comparePrice > price && <del>{comparePrice}</del>}
			{discountText && <span className={styles.discount_tag}>{discountText}</span>}
		</p>
	)
}

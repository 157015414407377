import React from 'react'
import { RegionShort } from 'utils/internationalization'
import { AffirmLogo } from './affirmLogo'
import { KlarnaLogo } from './klarnaLogo'

function financingLogo(region: RegionShort) {
	if (region === '' || region === 'us' || region === 'ca') {
		return <AffirmLogo className="affirmTransform" />
	}

	if (region === 'eu') {
		return <KlarnaLogo color="#000" />
	}
	return <></>
}

export default financingLogo

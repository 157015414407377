import { ProductVisual } from 'shop/shopManager'
import { Img } from 'components/basic/Img'
import { ShopCarouselVideo } from 'components/WebEv/Shop/HelperComponents'
import styles from './ShopImages.module.scss'
import { FC } from 'react'
import cx from 'classnames'

interface Props {
	images: ProductVisual[]
	selectedIndex: number
	imageOverride?: number
}

export const ShopImages: FC = (props: Props) => {
	if (props.imageOverride) {
		return (
			<div>
				<CarouselItem
					visual={props.images[props.imageOverride]}
					selected={true}
				/>
			</div>
		)
	}
	return (
		<div>
			{props.images.map((image, index) => {
				return (
					<CarouselItem
						key={`carousel-image-item-${index}`}
						visual={image}
						selected={props.selectedIndex === index}
					/>
				)
			})}
		</div>
	)
}

interface CarouselItemProps {
	visual: ProductVisual
	selected: boolean
}

const CarouselItem: FC<CarouselItemProps> = (props) => {
	const { visual } = props
	if (visual.type === 'image') {
		return (
			<div className={cx(styles.visual, { [styles.selected]: props.selected })}>
				<Img
					src={visual.data.src}
					alt={visual.data.alt}
					objectFit={'cover'}
					className={styles.carousel_photo}
				/>
			</div>
		)
	}
	if (visual.type === 'video') {
		const video = visual.data
		return (
			<div className={cx(styles.visual, { [styles.selected]: props.selected })}>
				<ShopCarouselVideo
					src={video.src}
					poster={video.poster}
					autoPlay={true}
					// forcePause={currentSlide !== index}
				/>
			</div>
		)
	}
	return null
}

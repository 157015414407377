import shopStyles from 'components/WebEv/Shop/Shop.module.scss'
import styles from 'components/WebEv/Shop/SelectionContainers/CheckoutContainer/CheckoutContainer.module.scss'
import { Button } from 'components/WebEv/Buttons'
import * as React from 'react'
import { FC, useEffect } from 'react'
import cx from 'classnames'
import { CheckoutSelectionData } from 'components/WebEv/Shop/types'
import { useShop } from '../../context/ShopProvider'
import { PriceManager } from 'prices'
import { formatYoureSaving } from 'components/WebEv/Shop/utils/formatDiscount'
import { AffirmLogo } from 'components/_const_assets/affirmLogo'
import { useScrollThreshold } from 'components/_hooks/useScrollThreshold'
import { BenefitsContainer } from 'components/WebEv/Shop/HelperComponents/BenefitsContainer/BenefitsContainer'
import { getShippingTimeline } from 'components/WebEv/Shop/utils/shippingTimelines'
import { allProductsList } from 'products/utils'
import { KlarnaLogo } from 'components/_const_assets/klarnaLogo'
import { TruemedInfo } from 'components/TruemedInfo/TruemedInfo'

interface CheckoutContainerProps {
	selectionData: CheckoutSelectionData
}

export const CheckoutContainer: FC<CheckoutContainerProps> = (props) => {
	const { checkout, state, rootStore, dispatch } = useShop()
	const { price, discount, strikePrice } = state
	const { currency } = rootStore.priceStore
	const { selectionData } = props
	const { data } = selectionData
	const { checkoutButtonText } = data

	const size = state.activeSelections.size?.value
	const model = state.activeSelections.model?.value
	const product = Object.values(allProductsList).find((p) => p.handle === model)
	const variant = product?.variants[size]
	const variantName = variant ? variant.name : ''

	const name = state.productName + (variantName ? ` - ${variantName}` : '')

	const ref = React.useRef<HTMLDivElement>(null)

	const headerWiped = useScrollThreshold()

	useEffect(() => {
		// Controls when the floating checkout should be hidden
		const VISIBILITY_THRESHOLD = 0.5
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.intersectionRatio >= VISIBILITY_THRESHOLD) {
					// Component is more than 50% visible
					dispatch({ type: 'setFloatingCheckoutOpen', data: { open: false } })
				} else if (headerWiped) {
					dispatch({ type: 'setFloatingCheckoutOpen', data: { open: true } })
				}
			},
			{
				threshold: VISIBILITY_THRESHOLD, // 50% visibility threshold
			}
		)

		if (ref.current) {
			observer.observe(ref.current)
		}

		return () => {
			if (ref.current) {
				observer.unobserve(ref.current)
			}
		}
	}, [headerWiped])

	const membershipSelection = Object.values(state.activeSelections).find((s) => s.field === 'membership')
	const membership = membershipSelection ? membershipSelection.value : ''

	const affirmContent = (
		<a
			className={cx(styles.financing, 'affirm-site-modal')}
			data-provider="Affirm"
			data-track="['click','mouseover'], 'Financing', 'Pre qualify button for Affirm'"
			role="button"
			tabIndex={0}
			aria-label="Pre qualify button for affirm"
		>
			{currency === 'CAD' ? 'Finance monthly with ' : `or as low as ${PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.getAffirmFinancingAmount(price), currency)}/mo with `}
			<AffirmLogo />
		</a>
	)

	const klarnaContent = (
		<p className={cx(styles.financing)}>
			or pay in 3 with{' '}
			<KlarnaLogo
				color={'black'}
				className={styles.klarna_nudge}
			/>
		</p>
	)

	const usingAffirm = PriceManager.currencyHasAffirm(currency)
	const usingKlarna = PriceManager.currencyHasKlarna(currency)
	const showAffirmOrKlarna = usingAffirm || usingKlarna
	const financingContent = usingAffirm ? affirmContent : usingKlarna ? klarnaContent : null

	return (
		<div
			className={cx(shopStyles.selection_container, styles.container)}
			ref={ref}
		>
			<h2>{name}</h2>
			<div className={styles.pricing_container}>
				<p className={cx(shopStyles.pricing)}>
					<strong aria-label="Current price">{PriceManager.formatPriceToCurrencyNoDecimal(price, currency)}</strong>
					<s aria-label="Old price">{PriceManager.formatPriceToCurrencyNoDecimal(strikePrice, currency)}</s>
					<span>{formatYoureSaving(discount, currency)}</span>
				</p>
				{showAffirmOrKlarna && financingContent}
				<TruemedInfo className={styles.financing} />
			</div>

			{/*<p className={styles.description}>{getDescription(state)}</p>*/}

			<div className={styles.checkout_buttons}>
				<Button.Primary
					id={'checkout-button'}
					className={styles.checkout_button}
					onClick={() => {
						checkout('checkout-button')
					}}
					loading={rootStore.cartStoreNew.updatingCart}
					size="large"
				>
					{checkoutButtonText}
				</Button.Primary>
			</div>
			<p className={styles.order_now}>{state.orderNowMessage}</p>
			<BenefitsContainer
				warrantyAmount={membership === 'plus' ? 5 : 2}
				shippingTimeline={getShippingTimeline(state, rootStore.settingsStore.currentRegion)}
			/>
		</div>
	)
}

import cx from 'classnames'
import styles from 'components/WebEv/Shop/SelectionContainers/UpsellContainer/UpsellContainer.module.scss'
import shopStyles from 'components/WebEv/Shop/Shop.module.scss'
import selectionStyles from 'components/Phantom/_shop/Selection/Selection.module.scss'
import { PriceManager } from 'prices'
import * as React from 'react'
import { FC, useRef } from 'react'
import { UpsellSelectionData } from 'components/WebEv/Shop/types'
import { formatDiscount } from 'components/WebEv/Shop/utils/formatDiscount'
import { amClickedButton } from 'events/amplitude'
import { PricingInfo } from 'components/Phantom/_shop/PricingInfo'
import { ModalButtons } from 'components/Phantom/_shop/Selection'

interface UpsellContainerProps {
	selectionData: UpsellSelectionData
	error?: string
}

export const UpsellContainer: FC<UpsellContainerProps> = (props) => {
	const { selectionData, modals, modalClick } = props
	const currency = 'USD'
	const { title, description, data, modal, id, onChange, checked } = selectionData
	const checkboxref = useRef()

	function checkBoxManually() {
		const cb = checkboxref.current as HTMLInputElement
		onChange(id, JSON.stringify(!checked))
	}

	return (
		<div
			id={`selection-${id}`}
			className="shop-section"
		>
			<header className={selectionStyles.selection_header}>
				<h3 className={selectionStyles.selection_title}>{title}</h3>
				{description && <p className={selectionStyles.selection_subtitle}>{description}</p>}
			</header>

			<div
				className={cx(shopStyles.selection_container, styles.container)}
				onClick={checkBoxManually}
			>
				<div className={styles.top}>
					<div className={styles.checkbox_container}>
						<label className={cx(styles.checkbox_new)}>
							<input
								ref={checkboxref}
								type={'checkbox'}
								checked={checked}
								onChange={(e) => {
									amClickedButton('Upsell Checkbox', data.hiddenProductName)
								}}
							/>
						</label>
					</div>
					<div>
						<h2 className={'vh'}>{data.hiddenProductName}</h2>
						<h3 className={styles.product_title}>{data.productTitle}</h3>
						<div className={styles.info}>
							<p className={styles.product_description}>{data.productDescription}</p>
							<p className={cx(shopStyles.small_pricing, styles.pricing)}>
								<strong aria-label="Current price">{PriceManager.formatPriceToCurrencyNoDecimal(data.price, currency)}</strong>
								{data.strikePrice !== data.price && <s aria-label="Old price">{PriceManager.formatPriceToCurrencyNoDecimal(data.strikePrice, currency)}</s>}
								{data.discount.value > 0 && <span>{formatDiscount(data.discount, currency)}</span>}
							</p>
							<PricingInfo
								price={data.price}
								comparePrice={data.strikePrice}
								discountText={data.discount}
							/>
						</div>
					</div>
				</div>
				{data.icons && (
					<div className={styles.bottom}>
						{data.icons.map((icon, i) => (
							<div
								className={styles.iconrow}
								key={`upsell-icon-${i}`}
							>
								<img
									src={icon.image}
									alt={icon.text}
								/>
								<p>{icon.text}</p>
							</div>
						))}
					</div>
				)}
			</div>
			<ModalButtons
				modalButtons={props.modalButtons}
				modalClick={props.modalClick}
			/>
		</div>
	)
}

import { FC, useEffect } from 'react'
import styles from './Sticky.module.scss'
import { StickyProps } from './types'
import { BenefitProps, FinancingProvider } from 'components/_utils/utilityTypes'
import { Button } from 'components/Phantom/Button'
import { Icon } from 'components/Phantom/Icon'
import { observer } from 'mobx-react'
import cx from 'classnames'
import { StickyDefaults } from 'components/Phantom/_shop/defaults'
import { useRootStore } from 'components/_hooks/useRootStore'
import { PricingInfo } from '../PricingInfo'
import { TruemedInfo } from 'components/TruemedInfo/TruemedInfo'
import { chevron_right, Icon as NewIcon } from 'components/Icon'
import { FinancingInfo } from 'components/Financing/Financing'

/**
 * Connected version of the `<Sticky/>` component. Automatically subscribes to the shop store.
 */
export const StickyConnected = observer(() => {
	const {
		/*shopStore*/
	} = useRootStore()
	const props: StickyProps = StickyDefaults // TODO Put store access here

	return <Sticky {...props} />
})

/**
 * Floating sticky component that displays product information and pricing and a checkout button.
 *
 * **Usually connected to the RootStore using the `<StickyConnected/>` component**
 */
export const Sticky: FC<StickyProps> = (props) => {
	const { title, benefits, price, strikePrice, alternatePriceDesktop, alternatePriceMobile, financingProvider, buttonText, discountText, open, savings, financingAmount, loading } = props

	useEffect(() => {
		if (props.open) {
			document.body.style.setProperty('--shortcut_bump', '9rem')
		} else {
			document.body.style.setProperty('--shortcut_bump', '0px')
		}
	}, [props.open])

	return (
		<div
			className={cx(styles.outer, { [styles.outer_open]: open })}
			id={'floating-checkout'}
		>
			<div className={styles.desktop_container}>
				<DesktopTitleContainer
					title={title.split('+')[0].trim()}
					benefits={benefits}
				/>
				<DesktopPriceContainer
					price={price}
					strikePrice={strikePrice}
					alternatePrice={alternatePriceDesktop}
					buttonText={buttonText}
					savings={savings}
					financingProvider={financingProvider}
					financingAmount={financingAmount}
					handleCheckout={props.handleCheckout}
					loading={loading}
				/>
			</div>
			<div className={styles.mobile_container}>
				<MobileTopContainer
					title={title.split('+')[0].trim()}
					price={price}
					strikePrice={strikePrice}
					buttonText={buttonText}
					savings={savings}
					handleCheckout={props.handleCheckout}
				/>
				<MobileBottomContainer
					alternatePrice={alternatePriceMobile}
					financingProvider={financingProvider}
					benefits={[
						{
							icon: 'MoonLight',
							text: '30-night risk-free trial',
						},
					]}
					financingAmount={financingAmount}
				/>
			</div>
		</div>
	)
}

/**
 Desktop components
 */

const DesktopTitleContainer = (props: { benefits: BenefitProps[]; title: string }) => {
	const { benefits, title } = props

	return (
		<div className={styles.desktop_title_container}>
			<p className={styles.product_title}>{title}</p>
			<BenefitsList benefits={benefits} />
		</div>
	)
}

const DesktopPriceContainer = (props: {
	buttonText: string
	price: string
	strikePrice: string
	alternatePrice: string
	savings: string
	financingProvider: FinancingProvider
	financingAmount: string
	handleCheckout: () => void
	loading: boolean
}) => {
	const { buttonText, price, strikePrice, financingAmount } = props

	return (
		<div className={styles.desktop_price_container}>
			<div className={styles.pricing}>
				<PricingInfo
					price={price}
					comparePrice={strikePrice}
					discountText={props.savings}
				/>
				<p className={styles.alternate_pricing}>
					<FinancingInfo
						priceFormatted={financingAmount}
						className={styles.alternate_pricing_inner}
					/>
				</p>
				<TruemedInfo
					className={styles.alternate_pricing}
					textOnly
				/>
			</div>
			<Button.Dark
				id={'desktop-sticky-button'}
				onClick={props.handleCheckout}
				loading={props.loading}
			>
				{buttonText}
			</Button.Dark>
		</div>
	)
}

/**
 Mobile components
 */

const MobileTopContainer = (props: { buttonText: string; price: string; title: string; strikePrice: string; savings: string }) => {
	const { buttonText, price, title, strikePrice, savings } = props
	return (
		<div className={styles.mobile_top_container}>
			<div className={styles.mobile_product_info}>
				<p className={styles.product_title}>{title}</p>
				<div className={styles.pricing}>
					<PricingInfo
						price={price}
						comparePrice={strikePrice}
						discountText={savings}
					/>
				</div>
			</div>
			<Button.Dark
				id={'mobile-sticky-button'}
				onClick={props.handleCheckout}
			>
				{buttonText}
			</Button.Dark>
		</div>
	)
}

const MobileBottomContainer = (props: { benefits: BenefitProps[]; financingProvider: FinancingProvider; financingAmount: string }) => {
	const { benefits, financingProvider, financingAmount } = props
	return (
		<>
			<div className={styles.mobile_bottom_container}>
				<div className={styles.slides}>
					{financingProvider && (
						<div className={styles.mobile_alternate_pricing}>
							<p className={styles.alternate_pricing}>
								<FinancingInfo priceFormatted={financingAmount} />
							</p>
						</div>
					)}
					<div
						className={styles.mobile_alternate_pricing}
						style={{ minWidth: '250px' }}
					>
						<TruemedInfo />
					</div>
					<div>
						<BenefitsList benefits={benefits} />
					</div>
					<div className={styles.button_hider}></div>
				</div>
			</div>
			<button
				type={'button'}
				className={styles.button}
			>
				<NewIcon
					icon={chevron_right}
					color={'#ADADAD'}
				/>
			</button>
		</>
	)
}

/**
 Shared components
 */

const BenefitsList = (props: { benefits: BenefitProps[] }) => {
	const { benefits } = props
	return (
		<ul className={styles.benefits_list}>
			{benefits.map((benefit) => (
				<li key={benefit.text}>
					<Benefit {...benefit} />
				</li>
			))}
		</ul>
	)
}

const Benefit: FC<BenefitProps> = (props) => {
	const { icon, text } = props
	return (
		<div className={styles.benefit}>
			<Icon
				name={icon}
				color={'#999999'}
				size={16}
			/>
			<p>{text}</p>
		</div>
	)
}

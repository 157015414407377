import * as React from 'react'
import shopStyles from 'components/WebEv/Shop/Shop.module.scss'
import styles from './ProductDetails.module.scss'
import { PriceManager } from 'prices'
import { formatDiscount } from 'components/WebEv/Shop/utils/formatDiscount'
import { useShop } from 'components/WebEv/Shop/context/ShopProvider'
import { getBadgeText } from 'components/WebEv/Shop/utils/getSavingsBadge'
import cx from 'classnames'
import { useRouter } from 'next/router'
import financingLogo from 'components/_const_assets/financingLogo'
import { KlarnaLogo } from 'components/_const_assets/klarnaLogo'
import { smoothScrollToElement } from 'components/_utils/boundingBoxHelpers'
import { Img } from 'components/basic/Img'
import { IconMap } from 'components/_const_assets/icons'
import { Tooltip2 } from 'components/Tooltip2'
import { TruemedInfo } from 'components/TruemedInfo/TruemedInfo'

export const ProductDetails = () => {
	const { state, rootStore } = useShop()
	const { currency } = rootStore.priceStore
	const { price, strikePrice, discount } = state

	const router = useRouter()

	const badgeText = rootStore.productStore.regionSellsMattress() ? getBadgeText(state, rootStore.priceStore.currency) : null

	const affirmContent = (
		<a
			className={cx(styles.financing, 'affirm-site-modal')}
			data-provider="Affirm"
			data-track="['click','mouseover'], 'Financing', 'Pre qualify button for Affirm'"
			role="button"
			tabIndex={0}
			aria-label="Pre qualify button for affirm"
		>
			{currency === 'CAD' ? (
				<span>Finance monthly with</span>
			) : (
				<span>or as low as {PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.getFinancingAmount(price, rootStore.settingsStore.currentRegion), currency)}/mo with </span>
			)}
			{financingLogo(rootStore.settingsStore.currentRegion)}
		</a>
	)

	const klarnaContent = (
		<p className={cx(styles.financing)}>
			or pay in 3 with{' '}
			<KlarnaLogo
				color={'black'}
				className="klarnaTransform"
			/>
		</p>
	)

	const usingAffirm = PriceManager.currencyHasAffirm(currency)
	const usingKlarna = PriceManager.currencyHasKlarna(currency)
	const showAffirmOrKlarna = usingAffirm || usingKlarna
	const financingContent = usingAffirm ? affirmContent : usingKlarna ? klarnaContent : null

	return (
		<div className={styles.product_details}>
			{badgeText && (
				<Tooltip2
					content={`Save up to ${badgeText.discount} when you bundle with our Sleep Essentials Bundle.`}
					id="savings-badge-tooltip"
				>
					<span
						className={shopStyles.savings_badge}
						onClick={() => {
							smoothScrollToElement(document.getElementById(`selection-sleepessentialsbundle`), window.innerHeight / 2)
						}}
					>
						{badgeText.text}
						<Img
							onClick={() => smoothScrollToElement(document.getElementById(`selection-sleepessentialsbundle`), window.innerHeight / 2)}
							unoptimized
							src={IconMap.chevron_down}
							lazy={false}
							alt="arrow pointing down"
							className={shopStyles.arrow_down}
						/>
					</span>
				</Tooltip2>
			)}
			<h1>Pod Cover</h1>
			<p className={cx(shopStyles.pricing, { [shopStyles.pricing_google]: router.asPath.includes('/shop-pod-cover') })}>
				<strong aria-label="Current price">{PriceManager.formatPriceToCurrencyNoDecimal(price, currency)}</strong>
				<s aria-label="Old price">{PriceManager.formatPriceToCurrencyNoDecimal(strikePrice, currency)}</s>
				<span>
					{formatDiscount(discount, currency)}
					{/*{savingsTag && '.'} {savingsTag}*/}
				</span>
			</p>
			{showAffirmOrKlarna && financingContent}
			<TruemedInfo className={styles.financing} />
		</div>
	)
}

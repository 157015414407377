import { FC } from 'react'
import styles from './SelectionSummary.module.scss'
import { SelectionSummaryProps } from './types'
import { Button } from 'components/Phantom/Button'
import { BenefitProps } from 'components/_utils/utilityTypes'
import { Icon } from 'components/Phantom/Icon'
import { observer } from 'mobx-react'
import { useRootStore } from 'components/_hooks/useRootStore'
import { SelectionSummaryDefaults } from '../defaults'
import { PricingInfo } from '../PricingInfo'
import { TruemedInfo } from 'components/TruemedInfo/TruemedInfo'
import { FinancingInfo } from 'components/Financing/Financing'

/**
 * Connected version of the `<SelectionSummary/>` component. Automatically subscribes to the shop store.
 */
export const SelectionSummaryConnected = observer(() => {
	const {
		/*shopStore*/
	} = useRootStore()
	const props: SelectionSummaryProps = SelectionSummaryDefaults // TODO Put store access here

	return <SelectionSummary {...props} />
})

/**
 * Final shop page component. Displays the total price, order items, shipping text, and benefits.
 *
 * **Usually connected to the RootStore using the `<SelectionSummaryConnected/>` component**
 */
export const SelectionSummary: FC<SelectionSummaryProps> = (props) => {
	const { price, alternatePrice, orderItems, buttonText, shippingText, benefits, financingAmount, savings, loading } = props
	return (
		<section
			className={styles.container}
			id={'selection-summary'}
		>
			<div className={styles.pricing}>
				<PricingInfo
					price={price}
					comparePrice={alternatePrice}
					discountText={savings}
					big
				/>
				<p className={styles.alternate_pricing}>
					<FinancingInfo priceFormatted={financingAmount} />
				</p>
				<TruemedInfo className={styles.alternate_pricing} />
			</div>
			{/* <div className={styles.order_summary}>
				<p>Your order includes</p>
				<strong>{orderItems}</strong>
			</div> */}
			<div className={styles.button_container}>
				<Button.Dark
					id={'selection-summary-button'}
					onClick={props.handleCheckout}
					loading={loading}
				>
					{buttonText}
				</Button.Dark>
			</div>
			<div className={styles.shipping_and_benefits}>
				<p className={styles.shipping_text}>{shippingText}</p>
				<ul className={styles.benefits}>
					{benefits.map((benefit, index) => (
						<li key={benefit.text}>
							<Benefit
								key={index}
								{...benefit}
							/>
						</li>
					))}
				</ul>
			</div>
		</section>
	)
}

export const Benefit: FC<BenefitProps> = (props) => {
	const { icon, text } = props
	return (
		<div className={styles.benefit}>
			<Icon
				name={icon}
				color={'black'}
				size={24}
			/>
			<p>{text}</p>
		</div>
	)
}

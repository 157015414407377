import { FC } from 'react'
import styles from './SideNav.module.scss'
import { SideNavProps } from './types'
import { Button } from 'components/Phantom/Button'

export const SideNav: FC<SideNavProps> = (props) => {
	const { numSlices, onClick, selectedSlice } = props
	return (
		<ul
			className={styles.container}
			style={{
				gridTemplateRows: `repeat(${numSlices}, 1fr)`,
			}}
		>
			{Array.from({ length: numSlices }).map((_, i) => (
				<li
					key={i}
					className={styles.slice}
				>
					<Button.Empty
						id={`side-nav-button-${i}`}
						onClick={() => onClick(i)}
						className={styles.button}
					>
						<span
							style={{
								opacity: selectedSlice === i ? 1 : 0.25,
							}}
							className={styles.button_interior}
						/>
					</Button.Empty>
				</li>
			))}
		</ul>
	)
}

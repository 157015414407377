import { FC } from 'react'
import styles from './ModelModal.module.scss'
import { ModelModalProps } from './types'
import { Pod4ComparisonTable } from 'components/Pod4Comparison'
import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'

export const ModelModalConnected = observer(() => {
	const { metricRegion } = useRootStore().settingsStore
	return <ModelModal metric={metricRegion} />
})

export const ModelModal: FC<ModelModalProps> = (props) => {
	const { metric } = props
	return (
		<div className={styles.container}>
			<Pod4ComparisonTable metric={metric} />
		</div>
	)
}

import { PriceManager } from 'prices'
import { Currency } from 'prices/types'
import { Discount } from 'components/WebEv/Shop/types'

export const formatDiscount = (discount: Discount, currency: Currency) => {
	return discount.type === 'percentage' ? `${discount.value * 100}% off` : `${PriceManager.formatPriceToCurrencyNoDecimal(discount.value, currency)} off`
}

export const formatYoureSaving = (discount: Discount, currency: Currency) => {
	// return discount.type === 'percentage' ? `You're saving ${discount.value * 100}%` : `You're saving ${PriceManager.formatPriceToCurrencyNoDecimal(discount.value, currency)}`
	return discount.type === 'percentage' ? `${discount.value * 100}% off` : `${PriceManager.formatPriceToCurrencyNoDecimal(discount.value, currency)} off`
}

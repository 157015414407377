import { FC } from 'react'
import styles from './ProductTitle.module.scss'
import { ProductTitleProps } from './types'
import { PricingInfo } from '../PricingInfo'
import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'
import { TruemedInfo } from 'components/TruemedInfo/TruemedInfo'
import { PriceManager } from 'prices'
import { FinancingInfo } from 'components/Financing/Financing'
import cx from 'classnames'

/**
 * Connected version of the `<ProductTitle/>` component. Automatically subscribes to the shop store.
 */
export const ProductTitleConnected = observer((props2) => {
	const { shopStore, priceStore } = useRootStore()

	const props: ProductTitleProps = {
		...shopStore.productInfo,
		financingProvider: priceStore.financingProvider,
		financingAmount: shopStore.selectedPodVariantFinancing,
	}

	return <ProductTitle {...props} />
})

/**
 * First shop page component. Displays the product title, price, and financing information.
 *
 * **Usually connected to the RootStore using the `<ProductTitleConnected/>` component**
 */
export const ProductTitle: FC<ProductTitleProps> = (props) => {
	const { title, price, comparePrice, priceString, comparePriceString, currency } = props
	return (
		<section className={cx(styles.container, 'connected-title')}>
			<h2 className={styles.product_title}>{title}</h2>
			<PricingInfo
				price={priceString}
				comparePrice={comparePriceString}
				discountText={`${PriceManager.formatPriceToCurrencyNoDecimal((comparePrice - price) / 100, currency)} off`}
			/>
			<span className={styles.financing_info}>
				<FinancingInfo
					price={price}
					className={styles.financing_info_inner}
				/>
			</span>
			<TruemedInfo className={styles.financing_info} />
		</section>
	)
}
